.mobile-menu{
    width: 100vw;
    height: 100vh;
    display: none;
    background-color: rgba(0,0,0,0.3);
    position: fixed;
    left: 0;
    right: 0;
    z-index: 999 !important;
    opacity: 0;
    box-shadow: 0px 29px 80px rgba(0,0,0,0.3);
    transition: all 0.3s ease;
    transform: translateX(-100vw);
    }
        
.mobile-menu-content{
    width: 50vw;
    height: 100vh;
    background-color: #342864;
    padding: 2rem;
}

.mobile-menu.active{
    opacity: 1;
    transform: translateX(0);
}

.mobile-menu-content li{
    margin: 0 1.5rem;
}

/* .mobile-menu-content li a.active{
    color: var(--active-color);
} */

.mobile-menu-content .nav-item{
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 500;
    color: #fff;
    position: relative;
    cursor: pointer;
    
}

.mobile-menu img{
    margin-bottom: 3rem;
}

.mobile-menu .nav-items{
    display: flex;
    flex-direction: column;
    gap:2rem;
    list-style: none;
}

@media (max-width: 769px) {
    .mobile-menu{
        display: block;
    }
    
}

.social-icons{
    display: flex;
    gap: 20px;
    margin-bottom: 3rem;
}