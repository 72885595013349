.navbar {
    /* background: rgba(0, 0, 0, 0.1); */
    /* background-color: var(--sidebar-color); */
    /* padding: 0.5rem 0; */
    /* position: sticky; */
    padding: 0;
    top: 0;
    z-index: 190;
    backdrop-filter: blur(70px);
    height: 60px;
    position: fixed;
    width: 100%;
}

.nav-content {
    padding: 5px;
    max-width: 1300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 2vw;
    /* padding: 1rem 0; */
    /* margin-left: 8vw; */
    /* margin: 0 auto; */
}

.logo {
    width: 9rem;
    height: auto;
    transition: all 0.3s ease-in-out;
}

.nav-content ul {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    list-style: none;
    margin: 3px;
}

.nav-content li {
    margin: 0 1.5rem;
}


.nav-content li a.active {
    color: var(--active-color);
}

.nav-content .nav-item {
    text-decoration: none;
    /* font-size: 1.0rem; */
    font-size: 18px;
    font-weight: 500;
    color: var(--text-color);
    position: relative;
    cursor: pointer;
}

.nav-content .nav-item::before {
    content: " ";
    width: 2rem;
    height: 0.2rem;
    background: var(--color-gradient);
    border-radius: 0.5rem;
    position: absolute;
    bottom: -0.6rem;
    opacity: 0;
    transform: translate(-1.5rem);
    transition: all 0.3s ease;
}

.nav-content .nav-item:hover::before {
    width: 100%;
    transform: translateX(0);
    opacity: 1;
}

.menu-btn {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0.4rem;
    color: #fff;
    background: linear-gradient(90deg, #a993fe -0.13%, #7e61e7 99.87%);
    line-height: 0;
    cursor: pointer;
    transition: all 0.4s ease;
    display: none;
}

.menu-btn:hover {
    color: #a993fe;
    background: #000;
    border: 1px solid #a993fe;
}

.resume {
    text-decoration: none;
    font-size: 1.0rem;
    font-weight: 500;
    color: var(--text-color);
    cursor: pointer;
}

.resume:hover {
    color: var(--active-color);
}

@media (max-width: 425px) {
    .logo {
        width: 8rem;
        height: auto;
    }
}

@media (max-width: 769px) {
    .menu-btn {
        display: block;
        margin: 10px;
    }

    .nav-content ul {
        display: none;
    }
}


@media (max-width: 1325px) {
    .navbar {
        padding: 0 0.5rem;
    }
}

.svg-container svg {
    transition: fill 0s;
    /* Add transition property to smoothly change fill color */
}

.svg-container:hover svg {
    fill: var(--active-color);
    /* Make the button glow */
    filter: drop-shadow(0 0 10 20px var(--active-color) );
    /* Change this to the desired fill color on hover */
    cursor: pointer;
}