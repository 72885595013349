/* .work {
    display: grid;
    row-gap: 50px;
} */
.work {
    display: flex;
    flex-direction: column; /* Display cards in a single column */
    row-gap: 50px;
  }

.work .custom-card{
    z-index: 1;
}

.work .custom-card:nth-child(1) {
    grid-column: 1;
    grid-row: span 5;
}

.work .custom-card:nth-child(2) {
    grid-column: 2;
    grid-row: 2 / span 9;
}

.work .custom-card:nth-child(3) {
    grid-column: 3;
    grid-row: 1 / span 9;
}

.work .custom-card:nth-child(4) {
    grid-column: 1;
    grid-row: 7 / span 9;
}

.work .custom-card:nth-child(5) {
    grid-column: 2;
    grid-row: span 5;
}

.work .custom-card:nth-child(6) {
    grid-column: 3;
    grid-row:10 / span 9;
}

@media (min-width: 768px) {
    .work {
        display: grid;
        row-gap: 50px;
    }
  }
  