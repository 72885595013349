.skills-container {
    margin-left: 3%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    /* flex-wrap: hidden; */
    /* display: grid; */
    /* grid-template-columns: auto; */
    /* grid-template-columns: repeat(7,5rem);   */
    column-gap: 60px;
    row-gap: 30px;
    color: #fff;
}

#skills {
    width: 80vw;
}

.skill-name {
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    text-transform: capitalize;
    color: var(--text-color);
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: normal;
}

/* .skill-card{
    padding-left: 20px;
    padding-right:10px ;
} */
/* 
.skill-card {
    display: flex;
    flex-direction: column;
    align-items: center;
} */

.skill-level {
    margin-top: 20px;
}

/* .skill-card:hover{
    transform: scale(1.2,1.2);
} */
.skill-level svg {
    bottom: 0;
    width: 60px;
    height: 60px;
}

@media (max-width: 768px) {
    .skill-level svg {
        bottom: 0;
        width: 42px;
        height: 42px;
    }

    .skills-container {
        display: grid;
        margin-left: 5%;
        column-gap: 30px;
        row-gap: 20px;
    }
}