#contactus{
    width: 100%;
}

.contactus{
    display: flex;
}
.left-sections{
    width: 50%;
    padding: 0;
    padding-left:40px ;
}
.right-sections{
    width: 50%;
}
.forms{
    width: 80%;
}
.forms .contents{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 20px;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.forms label{
    font-size: 20px;
    /* font-weight: bold; */
    margin-bottom: 10px;
    color:var(--text-color);    
}

.contents input[type="text"],
.contents input[type="email"],
.contents textarea {
  border-radius: 20px; 
  height: 20px;
  padding: 10px; 
  border: 1px solid #ccc;
  background-color:var(--background-100);
  color:var(--text-color);
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 16px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--text-color)    ;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px var(--background-100);
}
/* .contents input[type="text"]:-webkit-autofill,
.contents input[type="email"]:-webkit-autofill,
.contents textarea:-webkit-autofill{
  border-radius: 20px; 
  height: 20px;
  padding: 10px; 
  border: 1px solid #ccc;
  background-color:var(--background-100);
  -webkit-box-shadow: 0 0 0px 1000px var(--background-100) inset;
  -webkit-text-fill-color: var(--text-color);
  color:var(--text-color);
  transition: background-color 5000s ease-in-out 0s;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 16px;
} */

.contents textarea{
    height: 50px;
}


@media (max-width: 1200px) {
    .left-sections{
        width: 100%;
    }
    .right-sections{
        width: 100%;
    }
    .contactus{
        display: block;
    }
  }