.app-container {
    display: flex;
    /* background-color: var(--background-color); */
    /* background-color: blue; */
    /* backdrop-filter: blur(100px); */
    width: 60%;
    height: 90%;
    margin-top: 5%;
    margin-bottom: 5%;
    
    /* margin-top:30px ; */
}

.left-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    overflow-y: auto;
}

.right-section {
    width: 60%;
    padding: 10px;
    margin-right: 30px;
    margin-top: 30px;
}

@media (max-width: 769px) {
    .image-container {
        width: 100%;
        height: 100vh;
    }

    .app-container {
        display: block;
    }

    .left-section {
        width: 100%;
        overflow-y: auto;
    }

    .right-section {
        width: 100%;
        padding: 10px;
    }

    .nav-content ul {
        display: none;
    }
}

.material-symbols-outlined {
    color: var(--text-color)
}

.material-symbols-outlined:hover {
    color: var(--active-color);
}

.back-btn {
    cursor: pointer;
    border: none;
    background-color: transparent;
}

.wrap {
    position: relative;
    padding: 8px;
}

.content-wrap:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
    top: 0;
    left: 0;
    z-index: 3;
    /* background: url(https://i.imgur.com/aGh7Wsr.png) no-repeat; */
    background: url("./finalphone.png") no-repeat;
    background-size: contain;
}

.content-wrap {
    width: 225px;
    height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-top: 2px;

}

.image-container {
    margin-left: -3px;
    margin-right: 23px;
    height: 100%;
    padding-bottom: 20px;
}

.image-container img {
    margin: 10px;
    width: 100%;
    display: block;
    margin-bottom: 32px;
}

.about-project p {
    color: var(--text-color);
    text-align: justify;
    /* line-height: 25px; */
    /* letter-spacing: ; */
}

.singleprojectpage {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(20px);
    visibility: hidden;
    opacity: 0;
    transition: 0.5s linear;
    
    /* transition: opacity .15s linear; */
}

.singleprojectpage.active {
    visibility: visible;
    opacity: 1;
}

.close-btn {
    position: absolute;
    top: 60px;
    right: 50px;
    /* right: calc(10vw); */
    cursor: pointer;
    max-width: 30px;
}


/* .language{
    background: rgba(128, 128, 128, 0.5) ;
    padding: 0.4rem 0.8rem;
    border-radius: 1000px;
    color: var(--text-color);
    margin: 6px;
} */
