.project-page {
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 1000;
    height: 100%;
    left: 0;
    top: 0;
    background-color: var(--background-color);
    /* left: 50%; */
    /* padding-left: 10px; */
}


.header {
    width: 100%;
    font-size: 34px;
    color: var(--active-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Lato", sans-serif;
    font-weight: 300;
}

.left {
    align-items: start;
    padding: 40px;
    padding-left: 60px;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.about-content {
    font-size: 20px;
    text-align: justify;
    line-height: 40px;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    padding-right: 20px;
    color: var(--text-color);
}

.close-button {
    cursor: pointer;
    max-width: 50px;
    min-width: 50px;
    font-size: 50px;
}

.language-used {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.right {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 40%;
}

.wrap-container {
    position: relative;
    padding: 8px;
    transform: perspective(500px) rotateY(-10deg);
}

.wrap-content-mobile:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
    top: 0;
    left: 0;
    z-index: 3;
    /* background: url(https://i.imgur.com/aGh7Wsr.png) no-repeat; */
    background: url("./finalphone.png") no-repeat;
    background-size: contain;
}

.wrap-content-mobile {
    width: 295px;
    height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-top: -2px;
    border-radius: 30px;

}

.image-mobile {
    margin-left: -12px;
    margin-right: 38px;
    height: 100%;
    /* padding-bottom: 40px; */
}

.image-mobile img {
    margin: 24px;
    width: 100%;
    display: block;
}

.wrap-content-web:before {
    content: "";
    position: absolute;
    width: 200%;
    height: 140%;
    margin: auto;
    top: 0;
    left: 0;
    z-index: 3;
    /* background: url(https://i.imgur.com/aGh7Wsr.png) no-repeat; */
    background: url("./laptop.png") no-repeat;
    background-size: contain;
}

.wrap-content-web {
    /* width: 600px; */
    /* height: 500px; */
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* scrollbar-width: none; */
    margin-top: -2px;
    border-radius: 30px;

}

/* .image-web {
    margin-left: -12px;
    margin-right: 38px;
    height: 100%;
} */

.image-web img {
    /* margin: 24px; */
    width: 100%;
    /* display: block; */
}







/* @keyframes slideIn {
    0% {
        transform: translateX(500px) scale(.2);
    }

    100% {
        transform: translateX(0px) scale(1);
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(300px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes expand {
    0% {
        transform: translateX(1400px);
    }

    100% {
        transform: translateX(0px);
    }
} */








.circle-blur {
    /* Ellipse 3 */
    position: absolute;
    width: 317px;
    height: 317px;
    right: -300px;
    /* left: 740px; */
    top: -71px;
    background: var(--active-color);
    filter: blur(250px);
}

.circle-blur1 {
    /* Ellipse 4 */
    position: absolute;
    width: 317px;
    height: 317px;
    left: 800px;
    top: 561px;
    background: var(--active-color);
    filter: blur(250px);
}

.circle-blur3 {
    /* Ellipse 4 */
    position: absolute;
    width: 317px;
    height: 317px;
    left: -450px;
    top: 0px;

    background: var(--active-color);
    filter: blur(250px);
}



@media (max-width: 768px) {
    .left{
        width: 85%;
        padding-left: 30px;
    }
    .right{
        width: 100%;
    }
    .project-page{
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .circle-blur,.circle-blur1,.circle-blur3{
        display: none;
    }

  }
