@font-face {
    font-family: "Exoct";
    src: url("https://assets.codepen.io/1480814/films.EXH_____.ttf")
  }
  figure {
    width: 160px;
    aspect-ratio: 1;
    margin: 0 0 60px;
    padding: 5px 20px 0;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 100%;
    cursor: pointer;
    position: relative;
    filter: drop-shadow(0 0 20px rgb(0 0 0/50%));
  }

  figure:before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    background: top/cover;
    transform-origin: bottom;
    filter: brightness(.9);
    transition: .5s;
  }
  figure:before {
    background-image: url(https://assets.codepen.io/1480814/necro-back.jpg)
  }
  figure + figure:before {
    background-image: url(https://assets.codepen.io/1480814/druid-bac.jpg)
  }
  /* img {
    grid-area: 1/1;
    width: 100%; 
    height: 100%;   
    object-fit: contain;
    object-position: top;
    filter: contrast(.8) brightness(.7);
    place-self: end center;
    transition: .5s;
  } */
  figcaption {
    grid-area: 1/1; 
    width: calc(100% + 40px);
    font-family: Exoct;
    color: #fff;
    font-size: min(32px,5vmin);
    text-align: center;
    place-self: end center;
    transform: perspective(500px) translateY(100%) rotateX(-90deg);
    backface-visibility: hidden;
    transform-origin: top;
    background: #000;
    transition: .5s;
  }

  /* yo thik */
  figure:hover img {
    width: 130%;
    height: 255%;
    filter: contrast(1);
  }

  figure:hover::before {
    filter: brightness(.3);
    transform: perspective(500px) rotateX(60deg);
  }
        figure:hover figcaption{
            transform: perspective(500px)translateY(100%) rotateX(-30deg);
        }
  
  /* body {
    margin: 0;
    min-height: 100vh;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: min(230px,35vmin);
    place-content: end center;
    gap: 50px;
    background: 
      linear-gradient(#0000,rgb(50 50 50 / 88%)),
      url(https://assets.codepen.io/1480814/diablo-reaper-of-souls-mw-1360x768.jpg) top/cover;
  }
   */