.sidebar{
    background-color: var(--sidebar-color);;
    overflow: hidden;
    z-index: 200;
    top: 0;
    left: 0;
    height: 100vh;
    position: sticky;
    width: 70px;
 }

.side-content {
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap:50px; */
}

.side-content .side-item {
    writing-mode: vertical-lr;
    text-decoration: none;
    font-size: 18px;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    color: var(--text-color);
    position: relative;
    cursor: pointer;
    padding : 20px 20px;
}

/* .side-content .side-item::before {
    content: " ";
    width: 2rem;
    height: 0.2rem;
    background: var(--color-gradient);
    border-radius: 0.5rem;
    position: absolute;
    bottom: -0.6rem;
    opacity: 0;
    transform: translate(-1.5rem);
    transition: all 0.3s ease;
}

.side-content .side-item:hover::before {
    width: 100%;
    transform: translateX(0);
    opacity: 1;
} */

.side-content .side-item.active{
    border-right: 2px solid;
    background-color: rgb(128, 128, 128,0.1) ;
    border-color: var(--active-color);
    transition: ease 0s;
}

.side-content .side-item:not(.active):hover{
    border-right: 2px solid;
    background-color: var(--hover-background-color); 
    border-color: var(--hover-line-color); 
    transition: ease 0s;
}

@media (max-width: 768px) {
    .sidebar {
      display: none;
    }
  }

/* .side-content ul li{
    list-style: none;
} */

/* .side-content ul{
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 0  ;
    align-items: center;
} */