.profile{
  display: inline-block;  
}

.homepage{
    margin-top: 30px;
    height: 85vh;   
}


/* .profile{
    font-size: 44px;
    color: var(--text-color);
} */
