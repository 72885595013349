@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

:root{
  --slides: 9;
  --slide-duration: 2000ms;
  --background-color: #f6f6f6;
  --nav-color: green  ;
  --text-color: #000;
  --svg-fill-color:#000;
  --selection-color: #ACCEF7;
  --hover-background-color: rgb(128, 128, 128,0.0);
  --background-100:rgb(237, 237, 237);
  
  /* --active-color:#3301e7; */
  /* --active-color:#e85757; */
  --active-color:#01a463;
  --color-gradient: linear-gradient(90deg,#987ffd,#3301e7 100%);
  --hover-line-color: rgb(1, 164, 99,0.6);
  --hover-lin-color: rgba(169, 169, 169, 0.2);
  --vertical-line-color: red;
  --sidebar-color: rgba(54, 51, 51, 0.05);
}

[data-theme = "dark"]{
  --sidebar-color: #060417;
  --background-color: #0d0b23;
  --text-color: #fff;
  --selection-color: #12f298;
  --svg-fill-color:rgba(235, 236, 243,0.9);
  --hover-background-color: rgb(128, 128, 128,0.02);
  --background-100:rgb(23, 20, 53);
  /* --active-color:#7e61e7; */
  /* 060417 */
  --active-color:#0aff9d;
  --color-gradient: linear-gradient(90deg,#a993fe,#7e61e7 100%);
  --hover-line-color: rgb(10, 255, 157, 0.6);
  --hover-lin-color:  rgb(41, 30, 47,0.5);
  --vertical-line-color: red;
}

*{
  transition: all 0.3s ease-in-out;
}
*body{
  font-family:"Poppins", sans-serif;
  margin: 0 !important;
  box-sizing: border-box;
  cursor: none;
}

body {
  cursor: none;
  margin: 0;
  color: #fff;
  /* background-color: #060417; */
  background-color: var(--background-color);
}

.title{
  color: var(--text-color);
  font-size: 34px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  position: relative;
  left:20px;
  margin-bottom: 30px;
  margin-top:40px;
  width: 90%;
}

.title::before {
  position: absolute;
  content: "";
  background: var(--text-color);
  height: 100%;
  width: 2px;
  left: -20px;
}

.second-title{
  color: var(--text-color);
  font-size: 30px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  position: relative;
  left:20px;
  margin-bottom: 30px;
  margin-top:30px;
}

.second-title::before {
  position: absolute;
  content: "";
  background: var(--text-color);
  height: 100%;
  width: 2px;
  left: -20px;
}

.body{
  /* width: 90vw; */
  width: 90%;
  margin-top: 70px;
  margin-left: 5vw;
  margin-right: 5vw;
  bottom: 0;
}

.home{
  display: grid;
  grid-template-columns: 70px 1fr;
}

@media (max-width: 768px) {
  .home {
    display: grid;
    grid-template-columns: 1fr;
  }
}

/* Text selection color */
::-moz-selection { 
  color: #060417;
  background: var(--selection-color);
}

::selection {
  color: #060417;
  background: var(--selection-color);
}

.material-symbols-outlined {
  color: var(--text-color)
}

.material-symbols-outlined:hover {
  color: var(--active-color);
}

#particles canvas{
  z-index: -1;
}
